import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export const useTokenHandler = (authData, dispatch, authToken, resetAuth) => {
  const [token, setToken] = useState(null);
  // seturltoken
  const urlToken = new URLSearchParams(window.location.search).get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      if (urlToken) {
        console.log("get token from url 1");
        setToken(urlToken || null);
      } else if (!urlToken && authData) {
        if (authData.refreshToken) {
          console.log("get token from redux");
          setToken(authData.refreshToken);
        }
      } else {
        navigate("/notallow");
      }
    } else {
      if (!urlToken & !authData) {
        dispatch(resetAuth());
      } else if (token) {
        dispatch(authToken({ refreshToken: token }));
      }
      console.log("token: " + token);
    }
  }, [token, authData.token]);

  return token;
};
